import React from "react";
import ErrorTitles from "./ErrorTitles";
import ErrorDescription from "./ErrorDescription";
import RipIconStatus from "./RipIconStatus";

const ErrorView = ({ statusCode, statusMessage }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ErrorTitles />
      <RipIconStatus statusCode={statusCode} statusMessage={statusMessage} />
      <ErrorDescription />
    </div>
  );
};

export default ErrorView;
