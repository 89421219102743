import styled from "styled-components";

export const IconContainer = styled.div`
  position: relative;
  .icon {
    margin-bottom: 0.5rem;
  }
  .statusContainer {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .statusCode {
    margin-bottom: 0;
  }

  .statusCode {
    margin: 0;
    color: #ff5c00;
  }
`;
