// @flow

import React from "react";

const ErrorTitles = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ color: "#FF5C00" }}>Oh snap!</h1>
      <h2 style={{ marginBottom: "2rem" }}>Our Hamsters died.</h2>
    </div>
  );
};

export default ErrorTitles;
