import React from "react";
import {IconContainer} from "./styled.js";
import { RipIcon } from "../Icons";

const RipIconStatus = ({ statusCode, statusMessage }) => {
  return (
    <IconContainer>
      <div className="icon">
        <RipIcon />
      </div>
      <div className="statusContainer">
        <h1 className="statusCode">{statusCode}</h1>
        <h3 className="statusMessage">{statusMessage}</h3>
      </div>
    </IconContainer>
  );
};

export default RipIconStatus;
