import React from "react";
import ErrorView from "../components/error/ErrorView";

class NotFoundPage extends React.Component {
  render() {
    return <ErrorView statusCode="404" statusMessage="Not found." />;
  }
}

export default NotFoundPage;
